export var formData = {
  receiptId: '',
  corporateName: '', // 公司名称
  supplier: '', // 供应商
  statementType: '', // 对账单类型
  statementTypeOption: [
    { label: '正常采购', value: '0' },
    { label: '分期付款', value: '1' }
  ],
  billingParty: '', // 开票方
  billingPartyOption: [
    { label: '开票方1', value: '1' },
    { label: '开票方2', value: '2' }
  ],
  invoiceAmountIncludingTax: '', // 含税开票金额
  noTaxInvoiceAmount: '', // 未税开票金额
  invoiceAmount: '', // 开票税额
  currency: '', // 币种
  currencyOption: [
    { label: 'RMB', value: '1' }
    // { label: 'USD', value: '2' }
  ],
  consignmentOrNot: '', // 是否寄售:0是,1不是
  remarks: '' // 备注
}

export var rules = {
  corporateName: [
    { required: true, message: '请输入公司名称/编码', trigger: 'blur' }
  ],
  supplier: [
    { required: true, message: '请输入供应商名称/编码', trigger: 'blur' }
  ],
  currency: [
    { required: true, message: '请选择币种', trigger: 'change' }
  ],
  statementType: [
    { required: true, message: '请选择对账单类型', trigger: 'blur' }
  ],
  billingParty: [
    { required: true, message: '请输入开票方名称/编码', trigger: 'blur' }
  ],
  consignmentOrNot: [
    { required: true, message: '请选择是否寄售', trigger: 'change' }
  ]
}
