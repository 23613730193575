<template>
<!-- 对账信息 -->
<div class="mainform">
  <el-form  class="content bascform" ref="form" :model="form" :rules="rules" label-width="125px">
      <div class="col col4">
        <el-form-item label="公司名称" prop="corporateName">
          <el-select size="small" filterable remote
            :remote-method="requestCompanyOption"
            :loading="companyLoading"
            v-model="form.corporateName" placeholder="编码/名称">
            <el-option v-for="(item) in companyOptions"
            :key="item.value"
            :label="item.value"
            :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="供应商" prop="supplier">
          <el-select size="small" filterable remote
            :remote-method="requestSupplierOption"
            :loading="supplierLoading"
            v-model="form.supplier" placeholder="编码/名称">
            <el-option v-for="(item) in supplierOptions"
            :key="item.value"
            :label="item.value"
            :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div  class="col col4">
        <el-form-item label="对账单类型" prop="statementType">
            <el-select v-model="form.statementType">
                <el-option :key="index" v-for="(itme,index) in formOption.statementTypeOption" :label="itme.label" :value="itme.value"></el-option>
             </el-select>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="开票方" prop="billingParty">
          <el-select size="small" filterable remote
            placeholder="编码/名称"
            :remote-method="requestDrawerOption"
            :loading="drawerLoading"
            v-model="form.billingParty">
            <el-option v-for="(item) in drawerOptions"
            :key="item.value"
            :label="item.value"
            :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
       <div  class="col col4" style="vertical-align: top;">
        <el-form-item label="含税开票金额" prop="invoiceAmountIncludingTax" >
            <span class="onlyText">{{form.invoiceAmountIncludingTax}}</span>
        </el-form-item>
      </div>
      <div  class="col col4">
        <el-form-item label="币种" prop="currency">
            <el-select  v-model="form.currency">
                 <el-option :key="index" v-for="(itme,index) in formOption.currencyOption" :label="itme.label" :value="itme.label"></el-option>
             </el-select>
        </el-form-item>
      </div>
      <div  class="col col4" style="vertical-align: top;">
        <el-form-item label="未税开票金额" prop="noTaxInvoiceAmount">
          <span class="onlyText">{{form.noTaxInvoiceAmount}}</span>
        </el-form-item>
      </div>
      <div class="col col4" style="vertical-align: top;">
         <el-form-item label="是否寄售" prop="consignmentOrNot">
            <el-radio-group v-model="form.consignmentOrNot">
              <el-radio label="0">是</el-radio>
              <el-radio label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
      </div>
       <div  class="col col4" style="vertical-align: top;">
        <el-form-item label="开票税额" prop="invoiceAmount">
          <span class="onlyText">{{form.invoiceAmount}}</span>
        </el-form-item>
      </div>
      <div class="col col4" style="vertical-align: top;">
         <el-form-item label="备注">
             <el-input v-model="form.remarks"></el-input>
          </el-form-item>
      </div>
  </el-form>
</div>

</template>

<script>
import Component from '@/assets/js/components.js'
import { rules, formData } from './js/createStatementconfig'
import { requestForm, baseURL } from '@/assets/js/http.js'
import axios from 'axios'
export default {
  name: 'StatementInfo',
  props: ['formData'],
  components: Component.components,
  data: function () {
    return {
      form: this.formData.data,
      formOption: formData,
      supplierOptions: [],
      companyOptions: [],
      drawerOptions: [],
      drawerLoading: false,
      companyLoading: false,
      supplierLoading: false,
      rules: rules
    }
  },
  created () {
    this.form.currency = 'RMB'
  },
  methods: {
    requestSupplierOption (val) { // 供应商
      this.supplierLoading = true
      this.supplierOptions = []
      requestForm('/api/reconciliation/reconciliation/querySupplier', 'post', { supplier: val }).then((Response) => {
        if (Response.code === '200') {
          for (const item of Response.data) {
            this.supplierOptions.push({ key: `${item.supplierCode}-${item.supplier}`, value: `${item.supplierCode}-${item.supplier}` })
          }
        }
        this.supplierLoading = false
      })
    },
    requestDrawerOption (val) { // 开票方
      this.drawerLoading = true
      this.drawerOptions = []
      requestForm('/api/reconciliation/reconciliation/queryBillingParty', 'post', { name: val }).then((Response) => {
        if (Response.code === '200') {
          for (const item of Response.data) {
            this.drawerOptions.push({ key: `${item.billingPartyCode}-${item.billingParty}`, value: `${item.billingPartyCode}-${item.billingParty}` })
          }
        }
        this.drawerLoading = false
      })
    },
    requestCompanyOption (val) { // 公司
      this.companyLoading = true
      this.companyOptions = []
      axios.post(`${baseURL}/api/sap/getCompanyCodeInfo`, val, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          token: localStorage.getItem('token')
        }
      }).then((Response) => {
        if (Response.data.code === 200) {
          for (const item of Response.data.data) {
            this.companyOptions.push({ key: `${item.companyCode}-${item.companyName}`, value: `${item.companyCode}-${item.companyName}` })
            this.companyCode = item.companyCode
          }
        } else {
          this.$message({
            message: Response.data.msg,
            type: 'error'
          })
        }
        this.companyLoading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/elForm.scss';
@import '@/assets/css/tableList.scss';
.mainform{
.form {
.el-form .col {
    margin: 8px 0px;
}
.content{
  max-width: 100%;
  margin: 0 auto;
}
}

}

</style>
